<template>
  <div>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <Dialog
        :dialog="dialog"
        :title="'Neues Angebot anlegen'"
        :values="invoiceCreateFields"
        :successButton="'Erstellen'"
        :icon="'mdi mdi-file-document'"
        :headLines="[]"
        :cut="4"
        @hideDialog="hideDialog"
        @emitNewData="createInvoice"
        @createEntry="startCreateCustomer($event)"
    ></Dialog>
    <CreateCustomer
        :dialog="dialogCustomer"
        @hideDialog="hideDialogCustomer"
    >
    </CreateCustomer>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import {error, msgObj, success, getDateForPicker} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const Dialog = () => import("@/components/dialog/FormDialogNew");
const CreateCustomer = () => import("@/components/dialog/createDialogs/CreateCustomer");

export default {
  name: "CreateInvoice",
  components: {
    Dialog,
    Message,
    CreateCustomer
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('settings', {
      invoiceSettings: 'invoiceSettings'
    }),
    ...mapGetters('customer', {
      customers: 'customersForSelection',
      createdCustomerId: 'customerCreatedId'
    }),
  },
  mounted() {
      this.$store.dispatch('customer/getCustomersForSelection', {
        uid: this.user.id
      }).then(()=>{
          this.invoiceCreateFields = this.getInvoiceCreateFields()
      }).catch((err)=>{
        this.message = error(err)
      })
    this.$store.dispatch('settings/getInvoiceSettings', {
      uid: this.user.id
    }).then(()=>{
      this.invoiceCreateFields = this.getInvoiceCreateFields()
    }).catch((err)=>{
      this.message = error(err)
    })
  },
  data() {
    return {
      createFields: [],
      dialogCustomer: false,
      message: msgObj(),
      invoiceCreateFields: []
    }
  },
  props: {
    dialog: Boolean,
    customerSelection: String,
    openAfterCreate: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  methods: {
    hideDialog() {
      this.$emit('hideDialog')
    },
    hideDialogCustomer() {
      this.dialogCustomer = false
    },
    startCreateCustomer() {
      this.dialogCustomer = true
    },
    createInvoice(newValues) {
      let newInvoice = this.getInvoiceFromInput(newValues)
      this.$store.dispatch('invoice/createInvoice', {
        newInvoice,
        uid: this.user.id,
        openAfterCreate: this.openAfterCreate
      }).then(() => {
            this.hideDialog()
            this.message = success('Rechnung wurde erfolgreich angelegt.')
          }
      ).catch((err) => {
        this.message = error(err)
      })
    },
    getInvoiceCreateFields() {
      return [
        {
          title: 'Status*',
          value: 'In Erstellung',
          id: 0,
          type: 'string',
          readonly:true
        },
        {
          title: 'Rechnungsnummer*',
          value: 'Entwurf',
          id: 1,
          type: 'string',
          readonly:true
        },
        {
          title: 'Betreff',
          id: 2,
          type: 'string',
        },
        {
          title: 'Kunde',
          value: this.customerSelection,
          id: 3,
          items: this.customers,
          type: 'autoComplete',
          icon: 'mdi-account-plus',
          entity: 'customer'
        },
        {
          title: 'Rechnungsdatum',
          value: getDateForPicker(new Date(Date.now())),
          id: 4,
          type: 'date'
        },
        {
          title: 'Leistungs / Lieferdatum',
          value: null,
          id: 5,
          type: 'string',
        },
      ]
    },
    getInvoiceFromInput(newValues) {
      let newInvoice = {
        invoiceNumber: 'Entwurf',
        subject: '',
        customerId: '',
        invoiceDate: 0,
        date: 0,
        status: 0,
        footer: this.invoiceSettings.footer,
        header: this.invoiceSettings.header,
        isQuote: true
      }
      for (let value of newValues) {
        switch (value.title) {
          case 'Betreff':
            newInvoice.subject = value.value
            break;
          case 'Kunde':
            newInvoice.customerId = value.value
            break;
          case 'Rechnungsdatum':
           // newInvoice.invoiceDate = value.value
            break;
          case 'Leistungs / Lieferdatum':
            newInvoice.date = value.value
            break;
        }
      }
      return newInvoice
    },

  },
}
</script>
